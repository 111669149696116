(function ($) {
  $(document).ready(function () {

    let lastScrollTop = 0;

    let startBtn = document.getElementById('sticky product-order-button');
    let hideMobileNavElements = document.querySelectorAll('.hide-mobile-nav');  // get all elements with the class

    $(window).on("scroll", function() {

      if (window.innerWidth <= 768) {
        let currentScrollTop = $(this).scrollTop();
        if (currentScrollTop > lastScrollTop && startBtn && hideMobileNavElements && currentScrollTop > 50 && lastScrollTop > 0 ) {
            // Downward scroll
            hideMobileNavElements.forEach(function(elem) {
                elem.classList.add("hidden-mobile");
            });

        } else if(startBtn && hideMobileNavElements) {
            // Upward scroll
            hideMobileNavElements.forEach(function(elem) {
                elem.classList.remove("hidden-mobile");
            });
        }
        // Remember the scroll position for the next comparison
        lastScrollTop = currentScrollTop;

      }
    });



    $('.navigation__has-dropdown').hover(function () {
      $(this).find('button').attr('aria-expanded', true);
    }, function () {
      $(this).find('button').attr('aria-expanded', false);
    });

    $('#toggle-nav').click(function () {

      $(this).toggleClass('is-active');
      $('#mobile-nav-content').toggleClass('is-active');
      $('body').toggleClass('menu-open');
      $('#mobileSearchContainer').hide();

    });

    $('#mobileSearchContainer').hide();

    $('#searchIcon').click(function () {
      $('#mobileSearchContainer').toggle();
      $('#mobileSearchContainer input').focus();

      $('#toggle-nav').removeClass('is-active');
      $('#mobile-nav-content').removeClass('is-active');
    });

    var backButton = `<li class="mobile-nav-back-container">
        <button id="mobile-nav-back"><svg class="mobile-navigation__back-icon" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 18.48 18.013"><path id="Path_776" data-name="Path 776"
        d="M12.1,5.166V18.194L6.4,12.5a1.176,1.176,0,0,0-1.656,0,1.161,1.161,0,0,0,0,1.645l7.686,7.686a1.161,1.161,0,0,0,1.645,0l7.686-7.686A1.163,1.163,0,0,0,20.121,12.5l-5.692,5.692V5.166a1.166,1.166,0,1,0-2.333,0Z"
        transform="matrix(0.017, 1, -1, 0.017, 22.094, -4.477)" fill="#000" /></svg>BACK</button>
        </li>`;

    $('.mobile-navigation__dropdown-button').click(function () {

      var getUl = $(this).data('ul');
      // console.log('getUl', getUl);
      var ulHtml = $('ul#' + getUl).html();
      // console.log('ulHtml', ulHtml);

      var thisEl = $(this);

      $('#mobile-nav-sidebar ul').append(backButton + ulHtml);
      $('.mobile-navigation__side-list').addClass('is-active');

      thisEl.attr('aria-expanded', true);

      $('#mobile-nav-back').click(function () {
        $('.mobile-navigation__side-list').removeClass('is-active');
        $('#mobile-nav-sidebar ul').empty();
        thisEl.attr('aria-expanded', false);
      });

      bindToggleFunnelClickEvents();
    });

    function bindToggleFunnelClickEvents() {
      $('.toggle-funnel').on('click tap', function (e) {
        $('.mobile-navigation__side-list').removeClass('is-active');
        $('#toggle-nav, #mobile-nav-content, #mobile-nav-sidebar').removeClass('is-active');
        $('#mobile-nav-sidebar ul').empty();
        window.toggleFunnel(e);
      });
    }

    bindToggleFunnelClickEvents();

  });
})(jQuery);
import {
  isBefore,
} from 'date-fns';

function areDatesAvailable(type) {
  let datesAvailable = false;

  if (!$('a.' + type + '_datelink').length) {
    return;
  }

  $('a.' + type + '_datelink').each(function () {
    // At least one day isn't grayed out
    if (!$(this).hasClass('grayedOut')) {
      datesAvailable = true;
    }
  });

  return datesAvailable;
}

function chooseClosestAvailableDate(type, datesResponse) {

  function formatManual(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  }

  if (!$('#' + type + '_datepicker').length) {
    return;
  }

  // console.log('chooseClosestAvailableDate', type, datesResponse);

  // Add ther 'active' class to the date closest to today
  $('#' + type + '_datepicker .date-picker-non-calendar').each(function (index) {

    var this_date = $(this).data('datecheck'),
      linkType = $(this).data('ordertype');

    if ($(this).hasClass('grayedOut')) {
      return;
    }

    // Add active class
    $(this).addClass('active');

    $('#' + type + '_datepicker .dateLink').not(this).removeClass('active');

    // Assign date text
    $('#' + type + '_datepicker .ui-datepicker-trigger').html('More Dates');

    // Assign hidden value
    $(`#hidden_${linkType}_date`).val(this_date);

    // Do not pick closest available date, default to delivery always (LV-6518)
    // Select first available date
    // if (index === 0) {

    //   $(this).click();

    // }

    return false;

  });

  if (!datesResponse[(type === 'delivery' ? 'delivery_disabled' : 'pickup_disabled')]) {

    let date = new Date();
    let newdate = new Date(date);
    let listOfDates = [];
    newdate.setDate(newdate.getDate() + 30);

    while (formatManual(date) <= formatManual(newdate)) {

      listOfDates.push(formatManual(date));
      date = new Date(date.setDate(date.getDate() + 1));

    }

    let nextAvailableDate = listOfDates.find((d) => {
      if (
        (
          d === formatManual(new Date()) && !datesResponse[(type === 'delivery' ? 'delivery_today' : 'pickup_today')]
        ) ||
        datesResponse[(type === 'delivery' ? 'no_delivery_dates' : 'no_pickup_dates')].find((ndd) => ndd === d)
      ) {
        return;
      }
      return d;
    });

    $('#hidden_' + type + '_date, #' + type + '_datepicker_input').val(nextAvailableDate);

  }

  // Do not pick closest available date, default to delivery always (LV-6518)
  // if (
  //   type === 'pickup' &&
  //   $('#o_type').val() !== 'pickup' &&
  //   !datesResponse.pickup_disabled &&
  //   (
  //     datesResponse.delivery_disabled || isBefore(new Date($('#hidden_pickup_date').val()), new Date($('#hidden_delivery_date').val()))
  //   )
  // ) {

  //   $('#pickupTab').click();

  // }

  if (!areDatesAvailable(type)) {

    $('#' + type + '_datepicker .ui-datepicker-trigger').addClass('active');

  }

}

function openProductDatepicker(type) {

  // Datepicker (if no shortcut date chosen)
  if (!$('#' + type + '_datepicker_input').length || areDatesAvailable(type)) {
    return;
  }

  // If the element exists, open the datpicker
  $('#' + type + '_datepicker_input').datepicker('show');

}

export {
  openProductDatepicker,
  chooseClosestAvailableDate
}
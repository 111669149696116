var createSticky = (function ($) {

  return create;

  // Sticky Nav
  function create(element, scrollPoint) {

    var docElem = document.documentElement,
      didScroll = false,
      changeHeaderOn = scrollPoint;

    window.addEventListener('scroll', function () {

      if (!didScroll) {

        didScroll = true;
        setTimeout(scrollPage, 250);

      }

    }, false);

    function scrollPage() {

      var sy = scrollY();

      if (sy >= changeHeaderOn) {

        element.addClass('is-sticky');
        $(document.body).addClass('menu-is-sticky');

      } else {

        element.removeClass('is-sticky');
        $(document.body).removeClass('menu-is-sticky');

      }

      didScroll = false;

    }

    function scrollY() {

      return window.pageYOffset || docElem.scrollTop;

    }

  }

})(jQuery);

window.createSticky = createSticky;
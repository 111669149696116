import getUrlParameter from './get-url-parameter';
import {
  isAfter,
  endOfDay,
  format
} from 'date-fns';

(function ($, getUrlParameter, dateService) {

  // console.log('getUrlParameter', getUrlParameter);

  var usecodeParam = getUrlParameter('usecode');
  var optinParam = getUrlParameter('optin');
  var useCode = sessionStorage.getItem('lv.useCode');

  if (usecodeParam) {

    $('#couponModal').addClass(usecodeParam);
    getCoupon(usecodeParam);

  }

  if (optinParam) {
    optIn(optinParam);
  }

  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      closeAll();
    }
  });

  if ($('input#usecode').length && useCode) {

    // code input should be disabled by default to prevent empty param in checkout
    $('input#usecode').prop('disabled', false);
    $('input#usecode').val(useCode);

  }

  function setCouponCode(code) {

    var useCode = sessionStorage.getItem('lv.useCode');

    if (!useCode ||
      // Exists but doesn't match what's in the url
      useCode && useCode !== getUrlParameter('usecode')) {
      sessionStorage.setItem('lv.useCode', code);
    }

  }

  function optIn(optinParam) {

    var requestUrl = `${window.storeSettings.API_DOMAIN}/v1/contacts/email/optin/${optinParam}`;

    var optinRequest = jQuery.ajax({
      url: requestUrl,
      dataType: 'json',
      type: 'PUT',
    });

    optinRequest.done(function (response) {

      console.log(requestUrl, response);

    });
  }

  function getCoupon(couponCode) {

    var couponDetails = jQuery.ajax({
      url: `${window.storeSettings.API_DOMAIN}/v1/coupons/${window.storeSettings.account_id}/details/${couponCode}`,
      dataType: 'json',
      type: 'GET',
    });

    couponDetails.done(function (response) {

      const coupon = response.coupons_details && response.coupons_details[0];
      const isExpired = !coupon.expires ? false : dateService.isAfter(new Date(), dateService.endOfDay(new Date(coupon.expires)));

      if (isExpired && coupon.is_active) {
        return;
      }

      openModal(usecodeParam, coupon);
      setCouponCode(usecodeParam);

    });
  }

  function openModal(useCode, coupon) {

    $('#couponModal').fadeIn();
    $('#couponModalBg').addClass('is-active');
    $('#couponCode').text(useCode);
    $('#amount_off').text(coupon.is_percent ? coupon.value * 100 + '%' : '$' + coupon.value);
    $('#closeCouponModal, #couponModalBg').click(closeAll);

    if (coupon.expires) {
      $('#coupon_exp_date').text(dateService.format(new Date(coupon.expires), 'MMMM d, yyyy'));
    } else {
      $('#valid_through').remove();
    }

  }

  function closeAll() {
    $('#couponModal').fadeOut()
    $('#couponModalBg').removeClass('is-active');
  }

})(jQuery, getUrlParameter, {
  isAfter,
  endOfDay,
  format
});
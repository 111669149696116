(function ($) {

  var target;
  var deliveryPolicyLink = $('h4#Delivery_Policy');

  // For delivery
  if (deliveryPolicyLink) {

    var firstBackLink = $('a.backToTop')[0];

    $(firstBackLink).after('<div class="clear attention_sellingpoints">' +
      '<h3>Ordering During Holidays</h3>' +
      '<p>Our store and delivery hours may vary for major holidays. To help ensure on-time delivery during the busy holiday season, place your order at least one day prior to the following holidays:</p>' +
      '<ul>' +
      '<li>Valentine\'s Day</li>' +
      '<li>Easter</li>' +
      '<li>Administrative Professionals Week</li>' +
      '<li>Mother\'s Day</li>' +
      '<li>Father\'s Day</li>' +
      '<li>Good Friday</li>' +
      '<li>Labor Day</li>' +
      '</ul>' +
      '</div>');

  }

  if (window.innerWidth <= 720) {
    // change arrow icon in mobile view of delivery policy page
    $('.pictogram').html('TOP');
  }

  // Anchor smooth scrolling
  $('.delivery-policy a[href*=\\#]:not([href=\\#])').click(function () {

    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') ||
      location.hostname === this.hostname) {

      target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

      if (target.length) {

        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);

        return false;

      }

    }

  });

})(jQuery);
import getUrlParameter from '../get-url-parameter';

(function ($, getUrlParameter) {

  var dataLayer = window.dataLayer = window.dataLayer || [];

  var isSPP = $('#is_single_product_page')
    .val() === '1';
  var brands = {
    1: 'UFN',
    2: '1800',
    4: 'FTD',
    5: 'TF',
    6: 'LV'
  };

  // Product Impressions and Clicks
  if (window.productList && Array.isArray(window.productList) && !isSPP) {

    // Set global title so we can grab it in the single product page to get normalized referrer
    // If single page, the only list is the recommendations, so statically set that
    var globalTitle = $('#global_title').val();
    sessionStorage.setItem('lv.ecommerce.list', globalTitle);

    var impressionProducts = window.productList.map((product, index) => ({
      name: product.name,
      id: product.id,
      price: product.price,
      brand: brands[product.selection_guide_id],
      category: 'main',
      variant: 'standard',
      // Current list
      list: globalTitle,
      position: index + 1
    }));

    console.log('impressionProducts', impressionProducts);

    dataLayer.push({
      event: 'lv.storefront.productImpressions',
      ecommerce: {
        impressions: impressionProducts
      }
    });

  }

  // Record product clicks and impressions
  const productId = getUrlParameter('product-id');
  const product = window.product && window.product.AccountProduct;

  if (isSPP &&
    productId &&
    product &&
    product.id === productId) {

    // Track product clicks
    dataLayer.push({
      event: 'lv.storefront.productClick',
      ecommerce: {
        click: {
          actionField: {
            // Current list
            list: isSPP ? sessionStorage.getItem('lv.ecommerce.list') : globalTitle
          },
          products: [{
            name: product.name,
            id: product.id,
            price: product.price,
            brand: brands[product.selection_guide_id],
            category: 'main',
            // variant: 'standard',
            // position: index + 1
          }]
        }
      },
    });
  }

  // Promo click
  let splashId = getUrlParameter('splash-id');
  let splashName = getUrlParameter('splash-name');

  if (splashId && splashName) {

    // Track product clicks
    dataLayer.push({
      event: 'lv.storefront.promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [{
            id: splashId,
            name: splashName,
            creative: 'homepage_banner',
            position: 'banner'
          }]
        }
      },
    });

    dataLayer.push({
      event: 'lv.storefront.promotionImpression',
      ecommerce: {
        promoView: {
          promotions: [{
            id: splashId,
            name: splashName,
            creative: 'homepage_banner',
            position: 'banner'
          }]
        }
      }
    });

  }

})(jQuery, getUrlParameter);
(function ($) {

  $(document).ready(function () {
    var isFunnelOverlayOpen = false,
      isHowItWorksOpen = false;

    // initially hide funnel related icons
    $('#toggleFunnelOverlay, .toggle-funnel-close, #backNext, #funnelNavOverlay, #backNavOverlay').hide();

    // Hide how it works (test only)
    $('#howItWorks').hide();

    function toggleFunnel(e) {
      if (!window.ly) {
        console.error('window.ly does not exist');
        return;
      }

      window.ly.funnel.receive('backButtonDisabled', function () {
        $('#funnelBack, #funnelStartOver').prop('disabled', true);
      });

      window.ly.funnel.receive('backButtonEnabled', function () {
        $('#funnelBack, #funnelStartOver').prop('disabled', false);
      });

      window.ly.funnel.open($(e.currentTarget).data('prefill'));

      var dataLayer = window.dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: 'lv.funnel.open',
        attributes: {
          funnelEntryPoint: $(e.currentTarget).data('entrypoint-id'),
        }
      });

      // FUNNEL NAV BEHAVIOR
      $('#toggleFunnelOverlay, #backNext').show();
      $('#searchIcon').hide();

      // Disable countdown timer
      $('#countdownTimerv2ContainerDesktop, #countdownTimerv2ContainerMobile').remove()
    }

    window.toggleFunnel = toggleFunnel;

    function toggleFunnelOverlay() {
      if (!window.ly) {
        return;
      }

      isFunnelOverlayOpen = !isFunnelOverlayOpen;

      if (isFunnelOverlayOpen) {
        $('#funnelNavOverlay, .toggle-funnel-close').show();

        $('#backNext, #toggleFunnelOverlay').hide();
      } else {
        closeFunnelOverlay();
      }

      if (isHowItWorksOpen) {
        toggleHowItWorks();
      }
    }

    function closeFunnelOverlay() {
      isFunnelOverlayOpen = false;

      // TURN IT OFF
      $('#funnelNavOverlay, .toggle-funnel-close').hide();

      $('#backNext, #toggleFunnelOverlay').show();
    }

    function closeBackNav() {
      // Capture future main nav clicks again to show funnel menu
      $('#toggleFunnelOverlay').show();
      // Hide main nav click-capture overlay for the back nav
      $('#backNavOverlay').hide();

      // Trigger normal menu closed
      $('#toggle-nav').click();

      $('#searchIcon').hide();
      $('#backNext').show();
    }

    function toggleHowItWorks() {
      if (!window.ly || !window.ly.funnel) {
        return;
      }
      isHowItWorksOpen = !isHowItWorksOpen;

      if (isHowItWorksOpen) {
        $('#howItWorks').show();
        $('#funnelNavOverlay').addClass('just-back-close');
        // :|
        $('#funnelOverlayBack').contents()
          .filter(function () {
            return this.nodeType === 3;
          })
          .first().replaceWith('Back');
        window.ly.funnel.send('showHowItWorks');
      } else {
        $('#howItWorks').hide();

        $('#funnelNavOverlay').removeClass('just-back-close');

        // :|
        $('#funnelOverlayBack').contents()
          .filter(function () {
            return this.nodeType === 3;
          })
          .first().replaceWith('Main Menu');

        window.ly.funnel.send('hideHowItWorks');
      }
    }

    function goBack() {
      if (!window.ly) {
        return;
      }

      if (isHowItWorksOpen) {
        toggleHowItWorks();
      } else {
        closeFunnelOverlay();

        // Manually hide the funnel nav overlay so we can capture close with a different handler
        $('#toggleFunnelOverlay').hide();

        // Trigger normal menu open
        $('#toggle-nav').click();
        $('#searchIcon').show();
        $('#backNext').hide();
        $('#backNavOverlay').show();

        // If this exists, remov it
        $('#funnelNavOverlay').removeClass('just-back-close');
      }
    }

    function hurry() {
      if (!window.ly || !window.ly.funnel) {
        return;
      }

      window.ly.funnel.send('hurry');
      toggleFunnelOverlay();
    }

    function startOver() {
      if (!window.ly || !window.ly.funnel) {
        return;
      }

      // Ignore if disabled
      if ($('#funnelStartOver').prop('disabled')) {
        return;
      }

      window.ly.funnel.send('startOver');
      
      $('#funnelStartOver').prop('disabled', true);

      toggleFunnelOverlay();
    }

    function back() {
      if (!window.ly || !window.ly.funnel) {
        return;
      }

      // Ignore if disabled
      if ($('#funnelBack').prop('disabled')) {
        return;
      }

      window.ly.funnel.send('back');

      // Disable button to prevent race condition
      // between user and (assumed) incoming disable message
      $('#funnelBack').prop('disabled', true);
    }

    function next() {
      if (!window.ly || !window.ly.funnel) {
        return;
      }

      // Ignore if disabled
      if ($('#funnelNext').prop('disabled')) {
        return;
      }

      window.ly.funnel.send('next');

      // Disable button to prevent race condition
      // between user and (assumed) incoming disable message
      $('#funnelNext').prop('disabled', true);
    }

    $('.toggle-funnel').on('click tap', toggleFunnel);

    // Click invisible square over hamburger menu
    $('#toggleFunnelOverlay').on('click tap', toggleFunnelOverlay);

    $('.toggle-funnel-close').on('click tap', closeFunnelOverlay);

    $('#backNavOverlay').on('click tap', closeBackNav);

    $('#howItWorksToggle').on('click tap', toggleHowItWorks);

    $('#funnelOverlayBack').on('click tap', goBack);

    $('#funnelHurry').on('click tap', hurry);

    $('#funnelStartOver').on('click tap', startOver);

    $('#funnelBack').on('click tap', back);

    $('#funnelNext').on('click tap', next);
  });

})(jQuery);
(function ($) {

  // Send people to /overrideexclusivetheme on click of home page once they've moved past the temp theme
  // This is so they don't keep landing on the temp theme since they didn't want it
  if ($('#is_temp_theme_active').val() && $('#is_temp_theme_active').val().length > 0) {

    $('.JSVAR__lovingly-exclusive-override-slug').attr('href', '/overrideexclusivetheme');

  }

})(jQuery);
import Blazy from 'blazy';

window.lazyLoader = new Blazy({
    offset: 100,
    selector: '.b-lazy',
    loadInvisible: true,
    breakpoints: [{
        width: 375,
        src: 'data-src-small'
    }, {
        width: 720,
        src: 'data-src-medium'
    }],
    error: function (ele, msg) {
        console.error(ele, msg);
    }
});
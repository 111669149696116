import { set } from 'date-fns';
import {
  chooseClosestAvailableDate,
} from './chooseClosestAvailableDate';

(function ($, chooseClosestAvailableDate) {

  var hour,
    minute,
    seconds, 
    cutoff_time,
    datesResponse,
    timeLeft;

  function loadDates(is_fcplus = 0) {

    var loadPromise = new Promise((resolve) => { 

      var datesClosed = $.ajax({
        url: `/storefronts/datesClosed?fcplus=${is_fcplus}`,
        dataType: 'json'
      });

      datesClosed
        .done((response) => {

          datesResponse = response;

          return resolve(response);

        });

    });

    return loadPromise;

  }

  function setUpTimer(type, resp) {

    // Date countdown

    // new Date(year, mth-1, day, hr, min, sec)-date/time to count down to 
    hour = (type === 'delivery' ? datesResponse.delivery_cutoff_difference.h : datesResponse.pickup_cutoff_difference.h) * 3600000;
    minute = (type === 'delivery' ? datesResponse.delivery_cutoff_difference.i : datesResponse.pickup_cutoff_difference.i) * 60000;
    seconds = (type === 'delivery' ? datesResponse.delivery_cutoff_difference.s : datesResponse.pickup_cutoff_difference.s) * 1000;



    timeLeft = hour + minute + seconds;


    
    if (datesResponse[type + '_cutoff_difference'].invert === 1) {
      cutoff_time = new Date(new Date().getTime() + timeLeft);
    } else {
        cutoff_time = new Date(new Date().getTime() - timeLeft);
    }
  



    // The code that sets up the timer
    // format in lowercase means optional value
    // the layout option {h<}{h>} (hours for example) makes the optional sections disappear when they are zero
    // significant: 2 means show 2 significant values
    
    let countDownSettings = null;
    let datePickerPickupCountdownSettings = null;
    let datePickerDeliveryCountdownSettings = null;

    if (new Date() > cutoff_time) {
      pickupCountdownExpired(type);
    }
  

    if (type === 'pickup') {
      countDownSettings= {
        layout: '<div>{hnn}{hl}{sep}{mnn}{ml}{sep}{snn}{sl}<div>',
        until: cutoff_time,
        onExpiry: function () {
          pickupCountdownExpired('pickup');
        },
      };

      datePickerPickupCountdownSettings = {
        layout: '{hn} {hm} {mn} {mm} {sn} {sm}',
        compactCountdownLabels: ['yrs', 'mths', 'wks', 'dys', 'hrs', 'mins', 'secs'],  // Plural compact labels
        compactCountdownLabelsSingle: ['yr', 'mth', 'wk', 'dy', 'hr', 'min', 'sec'],  // Singular compact labels
        until: cutoff_time,
        onExpiry: function () {

          pickupCountdownExpired('pickup');
        },
      };
    } else if (type === 'delivery') {
      let todays_date = new Date();
      let formattedToday = todays_date.toISOString().split('T')[0]; // This will convert today's date to YYYY-MM-DD format

      if (resp.no_delivery_dates && resp.no_delivery_dates.includes(formattedToday)) {
        return $('#deliveryCutoffCopy').remove();
      }

      datePickerDeliveryCountdownSettings = {
        layout: '{hn} {hm} {mn} {mm} {sn} {sm}',
        compactCountdownLabels: ['yrs', 'mths', 'wks', 'dys', 'hrs', 'mins', 'secs'],  // Plural compact labels
        compactCountdownLabelsSingle: ['yr', 'mth', 'wk', 'dy', 'hr', 'min', 'sec'],  // Singular compact labels
        until: cutoff_time,
        onExpiry: function () {

          pickupCountdownExpired('delivery');
        },
      };
    }
  
    
    // Add a hidden timer for pickup cutoff
    $('body').append('<div class="hide" id="pickupCountdown"></div>');
    
    if (datePickerPickupCountdownSettings) {
      $('#date-picker-pickup-countdown').countdown(datePickerPickupCountdownSettings);
    }
  
    if (datePickerDeliveryCountdownSettings) {
        $('#date-picker-delivery-countdown').countdown(datePickerDeliveryCountdownSettings);
    }
    
    if (countDownSettings) {
        $('#pickupCountdown').countdown(countDownSettings);
    }

    $.countdown.setDefaults($('#isUserLocalFrench').val() ? $.countdown.regional.fr : $.countdown.regional['']);

  }

  function pickupCountdownExpired(type) {
    // This function fires off when the countdown timer hits 0
    // Update the delivery today field.  It's not necessary, but why not keep things organized
    if (type === 'pickup') {
      $('#pickupCutoffCopy').remove();


      // Disable the today button
      if ($('#pickup_datepicker .todayDate').hasClass('active')) {

        // Make today inactive
        $('#hidden_pickup_date').val('');
        $('#pickup_datepicker .todayDate').removeClass('active');
        $('#pickup_datepicker .todayDate').addClass('grayedOut');
        $('#pickup_datepicker .todayDate').attr('aria-disabled', 'true');
        
        loadDates()
          .then(datesResponse => {
            chooseClosestAvailableDate('pickup', datesResponse);
          });

      }
    } else if (type === 'delivery') {
      $('#deliveryCutoffCopy').remove();

    }

  }

  // support new countdown timer for gambit theme.
  function setUpDeliveryTimer(response) {

    // if both of them don't work, remove timer
    if (!response.delivery_today && !response.delivery_next_100hr) {
      $('#countdownTimerv2ContainerDesktop, #countdownTimerv2ContainerMobile').remove();

      //when timer is removed, adjust body padding 
      if (document.querySelector('style')) {
        document.querySelector('style').textContent +=
          `@media screen and (max-width:720px) {
            .body { padding-top: 50px !important; }
            .about-us__mobile-nav.is-sticky { top: 50px; }
          };`
      }

      return;
    }

    // timers are initially hidden, remove the force hidden class if timers are open
    $('#countdownTimerv2ContainerDesktop, #countdownTimerv2ContainerMobile').removeClass('hide-force');

    //creates onclick event for desktop
    $('#toggleBlock').click(function () {
      $('#satisfactionBottomContainer').slideToggle();
      $('#toggleIconOpen').toggle();
      $('#toggleIconClose').toggle();
    })

    let canStillDeliveryToday = (response.delivery_cutoff_difference.invert === 1 && response.delivery_today);

    let deliveryDayText = canStillDeliveryToday ? "Same Day Delivery" : "Next Day Delivery";
    let deliveryDayPreText = canStillDeliveryToday ? "Need it today?" : "In a Hurry?";

    let day, hour, minute, seconds;

    if (canStillDeliveryToday) {
      day = 0
      hour = response.delivery_cutoff_difference.h * 3600000;
      minute = response.delivery_cutoff_difference.i * 60000;
      seconds = response.delivery_cutoff_difference.s * 1000;
    } else {
      day = response.delivery_cutoff_diff_next.d * 3600000 * 24;
      hour = response.delivery_cutoff_diff_next.h * 3600000;
      minute = response.delivery_cutoff_diff_next.i * 60000;
      seconds = response.delivery_cutoff_diff_next.s * 1000;
    }

    timeLeft = day + hour + minute + seconds;
    cutoff_time = new Date(new Date().getTime() + timeLeft);

    $('#deliveryDayPreText').text(deliveryDayPreText);
    $('#deliveryDayTextDesktop, #deliveryDayTextMobile').text(deliveryDayText)

    //destroy countdown timers if existed
    $('#countdownTimerv2Desktop').countdown('destroy')
    $('#countdownTimerv2Mobile').countdown('destroy')

    $('#countdownTimerv2Desktop').countdown({
      layout: `            
        <div class="time-displayer">
          <div id="cdHour" class="time-displayer--display">{hnn}</div>
          <div class="time-displayer--text">hrs</div>
        </div>
        <div class="time-displayer--colon">{sep}</div>
        <div class="time-displayer">
          <div id="cdMinute" class="time-displayer--display">{mnn}</div>
          <div class="time-displayer--text">mins</div>
        </div>
        <div class="time-displayer--colon">{sep}</div>
        <div class="time-displayer">
          <div id="cdSecond" class="time-displayer--display">{snn}</div>
          <div class="time-displayer--text">secs</div>
        </div>
      `,
      format: 'HMS',
      until: cutoff_time,
      onExpiry: function () {
        setTimeout(
          function () {
            loadDates()
              .then(datesResponse => {

                setUpDeliveryTimer(datesResponse);

                // gray out todays date
                if (!datesResponse.delivery_today) {


                  $('#delivery_datepicker .todayDate').removeClass('active');
                  $('#delivery_datepicker .todayDate').addClass('grayedOut');
                  $('#delivery_datepicker .todayDate').attr('aria-disabled', 'true');
                }

                chooseClosestAvailableDate('delivery', datesResponse);

              });
          }, 300) // reset timer after 300 milliseconds, so it will refresh correctly
      }
    })

    $('#countdownTimerv2Mobile').countdown({
      layout: `
        <div id="cdHourMobile" class="time-displayer--display">{hnn}</div>
        <div class="time-displayer--colon">{sep}</div>
        <div id="cdMinuteMobile" class="time-displayer--display">{mnn}</div>
        <div class="time-displayer--colon">{sep}</div>
        <div id="cdSecondMobile" class="time-displayer--display">{snn}</div>
      `,
      format: 'HMS',
      until: cutoff_time,
      onExpiry: function () {
      }
    })

  }

  // Add this function to conditionally apply padding-top style
  function applyConditionalPadding() {
    const countdownTimer = document.querySelector('.countdown-timer__mobile');
    const covidBanner = document.querySelector('.covid-banner');
    const mobileDotdImage = document.querySelector('.mobile-dotd-image');
    
    if (mobileDotdImage) {
      if (countdownTimer) {
        mobileDotdImage.style.paddingTop = covidBanner ? '17px' : '38px';
      } else {
        mobileDotdImage.style.paddingTop = '0';
      }
    }
  }
  
  // if fcplus, get dates for fcplus theme when calculating next delviery timer
  var is_fcplus = 0;
  if (window.location.pathname.includes('florist-choice-plus')) {
    is_fcplus = 1;
  }

  loadDates(is_fcplus)
    .then(datesResponse => {
      if (!datesResponse.pickup_disabled) {
        setUpTimer('pickup');
      }
      // added for countdown timer v2
      setUpDeliveryTimer(datesResponse);
      setUpTimer('delivery', datesResponse); 
    });

  applyConditionalPadding(); 

})(jQuery, chooseClosestAvailableDate);
var lyReadMore = (function ($) {

  return readMore;

  function readMore(selector, optionOverrides) {

    const opts = Object.assign({}, {
      maxLength: 200,
      wordCount: 0,
      moreLinkText: '…Read More',
    }, optionOverrides);

    $(selector).each(function () {

      var content = $(this).html(),
        lessContent,
        moreContent;

      var count = content.split(/\s/).length;

      if (opts.wordCount && count > opts.wordCount) {

        lessContent = content.split(/\s/).map((c, i) => (i < opts.wordCount) ? c : '').join(' ');
        moreContent = content.split(/\s/).map((c, i) => (i >= opts.wordCount) ? c : '').join(' ');

      }

      if (!opts.wordCount && content.length > opts.maxLength) {

        lessContent = content.substr(0, opts.maxLength).trim();
        moreContent = content.substr(opts.maxLength, content.length - opts.maxLength);

      }

      if (moreContent) {

        var html = lessContent +
          '<span class="ly-read-more-content">' +
          moreContent +
          '</span>' +
          '&nbsp;&nbsp;' +
          '<button href="#" class="ly-read-more-link">' +
          opts.moreLinkText +
          '</button>';

        $(this).html(html);

        $(this).find('.ly-read-more-content').hide();

      }

    });

    $(selector + ' .ly-read-more-link').click(function () {

      $(this).prev().toggle();

      $(this).hide();

      return false;

    });

  }

})(jQuery);

export default lyReadMore;
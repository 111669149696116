(function ($) {
    var element = document.getElementById('back-to-top');

    const isContact = location.href.match("\/(about|contact)[-\_]us$")
    const isWeddingCMS = location.href.match("\/(wedding-flowers)$")
    const isWeddings = location.href.match("\/searchProds\?sr=(wedding|bridal|bride|groom|boutonniere|corsage|centerpiece|reception|toss|wed)")
    const isLV = window.storeSettings.services.weddings.is_active || window.storeSettings.services.funnel_on_shop.is_active;

    if (!element || (isContact && isLV) || (isWeddings && isLV) || (isWeddingCMS && isLV)) {
        return;
    }

    scrollFunction();

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = () => scrollFunction();

    function scrollFunction() {
        return document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ? element.classList.add('visible') : element.classList.remove('visible');
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;

        $('html, body').animate({
            scrollTop: 0
        }, 500);
    }

    element.addEventListener('click', () => topFunction());

})(jQuery);
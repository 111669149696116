import lyReadMore from 'read-more';

import './vendor/lightbox.js' //quiet

(function ($, lyReadMore) {
  var reviewsSectionElem = $('.geo-landing__reviews__container'),
    reviewsSectionContentElem = $('[data-name="reviews-all-desktop"]'),
    reviewsSectionMobileContentElem = $('[data-name="reviews-all-mobile"]'),
    reviewsMainSection = $('#geo-landing-reviews'),
    mobileShowMore = $('.mobile-show-more-reviews'),
    reviewsData,
    reviewIdx=0;

  window.lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true,
    'alwaysShowNavOnTouchDevices': true,
  });

  function init() {
    reviewsMainSection.hide()
    if ((!reviewsSectionElem || !reviewsSectionElem[0])) {
      return;
    }

    $.get('/reviews/about_us', onFetchReviews);

  }

  function lovinglyReviewsShowable(summary, reviews) {

    return summary &&
      reviews &&
      summary.average_rating >= 3;

  }


  function updateDesktopReviews(reviewsData) {
    reviewsSectionContentElem.append(reviewsData.reviews_html);

    lyReadMore('.reviews-carousel-review-content', {
      moreLinkText: '…Read More'
    });

    if (reviewsSectionContentElem.find('.reviews-section-rating-container')){
      reviewsSectionContentElem.find('.reviews-section-rating-container')
        .rateYo();
    }
    showAndEvaluate();
  }

  function updateMobileReviews(reviewsData) {
    var nextThree = reviewsData.reviews_html.slice(reviewIdx, reviewIdx+3);
    reviewIdx += 3;
    reviewsSectionMobileContentElem.append(nextThree);

    if (reviewIdx >= reviewsData.reviews_html.length) {
      mobileShowMore.hide();
    }

    if (reviewsSectionMobileContentElem.find('.reviews-section-rating-container')){
      reviewsSectionMobileContentElem.find('.reviews-section-rating-container')
        .rateYo();
    }
    showAndEvaluate();
  }

  function showAndEvaluate() {
    reviewsSectionElem.show();
    window.lazyLoader.revalidate();
  }

  function onFetchReviews(res) {
    reviewsData = res;
    if (!lovinglyReviewsShowable(reviewsData.summary, reviewsData.reviews)) {
      return;
    }
    if (!reviewsData.reviews_html || !reviewsData.reviews_html.length) {
      return;
    }
    reviewsMainSection.show();
    updateDesktopReviews(reviewsData);
    updateMobileReviews(reviewsData);

  }

  $(window).load(function () {
    init();

    $('.mobile-show-more-reviews').click(function (e) {
      updateMobileReviews(reviewsData);
    });
  });

})(jQuery, lyReadMore);
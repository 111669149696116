(function ($) {

  var activeModal = '';

  $('[data-reveal-id]')
    .click(function () {
      var modalId = $(this)
        .data('reveal-id');

      if (activeModal === modalId) {
        return;
      }

      activeModal = modalId;

      if ($('.reveal-modal-bg')
        .length === 0) {
        $('<div class="reveal-modal-bg" />')
          .appendTo('body');
        $('.reveal-modal-bg')
          .click(closeAll);
      }

      $('.reveal-modal-bg')
        .toggleClass('is-active');

      setTimeout(function () {
        $('#' + modalId)
          .removeClass('slideOutUp')
          .addClass('slideInDown');
      }, 200);

      setTimeout(() => {
        window.lazyLoader.revalidate();
      }, 1000);

    });

  $(document)
    .keyup(function (e) {
      if (e.key === "Escape") {
        closeAll();
      }
    });

  $('.close-reveal-modal, .reveal-modal-bg')
    .click(closeAll);

  function closeAll() {
    $('#' + activeModal)
      .removeClass('slideInDown')
      .addClass('slideOutUp');
    $('.reveal-modal-bg')
      .removeClass('is-active');
    activeModal = '';
  }
})(jQuery);
(function () {
    const element = document.getElementById('back-button');

    if (!element) {
        return;
    }

    if (document.referrer.indexOf(location.hostname) !== -1) {
        element.classList.remove('hide');
    }

    element.addEventListener('click', () => window.history.back());

})();
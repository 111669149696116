'use strict';

window.$ = window.jQuery = $;

import './vendor/fittext' //quiet

import './vendor/jquery/jquery.countdown' //quiet

import './vendor/star-rating' //quiet
import './back-button'
import './back-to-top'

import './analytics/ga-ecommerce'

import './sticky'
import './mobile-navigation'
import './reveal-modal'
import './delivery-policy'
import './lazy-loading'
import './theme-layout'

import './featured-links'

import './accessibility'

import './countdown-timer'
import './funnel-on-shop'

import './coupon-modal'
import './location-map_core'
import './analytics/ab-test'
import './geo-landing-reviews'
import lyReadMore from 'read-more';

(function ($, lyReadMore) {

  var href;

  if (window.innerWidth <= 720 && window.location.href.indexOf('about-us') > -1) {

    window.createSticky($('#tabNav'), 368);

  }

  window.createSticky($('#stickyHeader'), 156);

  $('.accordion dd a.toggleLink').click(function () {

    href = $(this).attr('href');
    $(href).toggleClass('active');
    $(this).children('span').toggleClass('turn');

  });

  // Homepage SEO should be truncated visually in mobile
  if (window.innerWidth <= 720) {
    lyReadMore('.homepage__seo-section p', {
      moreLinkText: '…read more',
      wordCount: 32
    });
  }

  // Shrink title text if too long
  $('#fittext1').fitText(1.2, {
    minFontSize: '18px',
    maxFontSize: '28px'
  });

  



})(jQuery, lyReadMore);